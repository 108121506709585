import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MHidden } from 'components/@material-extend';
import { LoginForm } from 'components/authentication/login';
import Page from 'components/Page';
//import AuthLayout from 'layouts/AuthLayout';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_AUTH } from 'routes/paths';
import MainNavbar from 'layouts/main/MainNavbar';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  return (
    <RootStyle title='Login | BİOS'>
      <MainNavbar />
      <Container maxWidth='sm'>
        <ContentStyle>
          <Stack direction='row' alignItems='center' sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant='h4' gutterBottom>
                Bios'a Giriş Yap
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Lütfen Bilgilerinizi Giriniz.</Typography>
            </Box>
          </Stack>
          <LoginForm />
          <MHidden width='smUp'>
            <Typography variant='body2' align='center' sx={{ mt: 3 }}>
              Hesabınız yok mu?&nbsp;
              <Link variant='subtitle2' component={RouterLink} to={PATH_AUTH.register}>
                Kayıt Ol
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
