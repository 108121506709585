import SvgIconStyle from '../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../routes/paths';

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  forum: getIcon('ic_forum'),
  gelen: getIcon('ic_gelen'),
  giden: getIcon('ic_giden'),
  users: getIcon('ic_users'),
};

const sidebarConfig = [
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', roles: ['*'], path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Taleplerim', roles: ['*'], path: PATH_DASHBOARD.request.list, icon: ICONS.giden },
      {
        title: 'Gelen Talepler',
        roles: ['Komisyon', 'Komisyon Sözcüsü'],
        path: PATH_DASHBOARD.gelenTalepler.list,
        icon: ICONS.gelen,
      },
      // {
      //   title: 'Onay Talepleri',
      //   roles: ['Komisyon', 'Komisyon Sözcüsü'],
      //   path: PATH_DASHBOARD.onayTalepleri.list,
      //   icon: ICONS.booking,
      // },
      /* {
        title: 'İştişare Talepleri',
        roles: ['Heyet', 'Komisyon','Komisyon Sözcüsü'],
        path: PATH_DASHBOARD.istisaredekiTalepler.list,
        icon: ICONS.forum,
      }, */
      { title: 'Kullanıcılar', roles: ['*'], path: PATH_DASHBOARD.kullanicilar.list, icon: ICONS.users },
      //{ title: 'Talep Arşivi', roles: ['Komisyon','Komisyon Sözcüsü'], path: PATH_DASHBOARD.talepArsiv.list, icon: ICONS.blog },
    ],
  },
];

export default sidebarConfig;
